<template>
    <transition>
        <div class="fixed top-0 left-0 w-full h-full flex justify-center" v-if="show" style="background: rgba(0, 0, 0, 0.4); z-index: 11;">
            <div class="block self-center w-1/2 bg-white p-3 rounded-lg">
                <div class="flex justify-between">
                    <span class="my-auto">LOGIN</span>
                    <div class="my-auto" @click="$store.commit('service/SET_LOGIN_POPUP', false)">
                        <b-icon icon="window-close" size="is-small"></b-icon>
                    </div>
                </div>
                <div class="border border-solid border-t-0 border-l-0 my-3"></div>
                <div class="block w-full mx-auto mb-0 py-2">
                    <div class="flex w-full">
                        <div class="block my-auto mb-0 w-full">
                            <form @submit="onsubmit" id="login-field">
                                <b-field>
                                    <b-input name="UserName" placeholder="username"></b-input>
                                </b-field>
                                <b-field>
                                    <b-input name="Password" placeholder="password"></b-input>
                                </b-field>
                                <div class="w-full">
                                    <b-button class="w-full" type="is-dark" @click="login">LOGIN</b-button>
                                </div>
                            </form>
                        </div>
                        <div class="border border-solid border-t-0 border-b-0 border-r-0 mx-5"></div>
                        <div class="block my-auto w-full">
                            <b-button class="w-full bg-indigo-600 text-white hover:text-white logo-button mb-2" icon-left="facebook">
                                Facebook
                            </b-button>
                            <b-button class="w-full bg-red-700 text-white hover:text-white logo-button" @click="AuthProvider('google')" icon-left="google">
                                Google
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="block text-center w-3/4 mx-auto">
                    <small class="block">
                        We’ll never spam you or share your personal information with anyone.
                        That’s a promise. By creating an account, you agree to accept Fave’s Terms of Use and Privacy Policy.
                    </small>
                    <small class="block text-blue-500 cursor-pointer">
                        Don't have accont yet
                    </small>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import BaseApi from '@/libs/baseApi'
import Vue from 'vue'
export default {
    computed: {
        show () {
            return this.$store.state.service.loginPopup
        }
    },
    methods: {
        async AuthProvider (provider) {
            var self = this
            this.$auth.authenticate(provider).then(response => {
                self.SocialLogin(provider, response)
            }).catch(err => {
                console.log({ err: err })
            })
        },
        async SocialLogin (provider, response) {
            console.log(provider, response)
            const result = await this.$portalApi.get('/auth/login/external/google/callback', { params: response })
            console.log('social login', result)
        },
        async login () {
            var data = this.getData('login-field')
            data.Company = this.company.Company
            data.Device = 'web_' + new Date().getTime()
            const response = await this.$portalApi({
                method: 'POST',
                data: data,
                url: '/auth/login'
            })
            var config = localStorage.getItem('config')
            config = JSON.parse(config)
            config.Token = response.access_token
            localStorage.setItem('config', JSON.stringify(config))
            var newBaseApi = new BaseApi(localStorage.getItem('config'))
            Vue.prototype.$baseApi = newBaseApi.api()
            this.$store.commit('service/SET_LOGIN_POPUP', false)
            await this.getCompany()
        }
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep .logo-button {
        span {
            width: 100%;
        }
    }
</style>
